<div>
  <a
    class="logo"
    (click)="closeMenu()"
    [routerLink]="['/generality', 'welcome']"
  >
    <img
      src="assets/images/logoFFAAA_5496x2278.png"
      alt="LGE Logo"
      loading="lazy"
    />
  </a>

  <button class="menu-bar" (click)="toggleMenuOpening()">
    <span *ngIf="!menuBarOpened" class="hamb-line"></span>
    <span *ngIf="menuBarOpened" class="close-line"></span>
  </button>

  <nav class="navbar" #navBar>
    <ul class="groups-items">
      <li>
        <a (click)="toggleMenuOpening()" [routerLink]="['/clubs', 'inscriptions']" >
        	Clubs
				</a>
      </li>
      <!--<li>
        <a (click)="toggleMenuOpening()" [routerLink]="['/generality', 'family-seminar']" >
        	Stage Familial
				</a>
			</li>-->
      <!--<li>
        <a (click)="toggleMenuOpening()" [routerLink]="['/generality', 'all-in-white']" >
					Tous en blanc
				</a>
			</li>-->
      <li
      (mouseenter)="displayMenu($event)"
      (mouseleave)="hideMenu($event)"
    >
      <a>Catégorie</a>
      <ul>
        <li>
          <a
            class="routerA"
            (click)="clickMenuItem($event)"
            [routerLink]="['/categorie', 'adultes']"
            >Adultes</a
          >
        </li>
        <li>
          <a
            class="routerA"
            (click)="clickMenuItem($event)"
            [routerLink]="['/categorie', 'enfants']"
            >Enfants</a
          >
        </li>
        <li>
          <a
            class="routerA"
            (click)="clickMenuItem($event)"
            [routerLink]="['/categorie', 'entreprises']"
            >Institutions</a
          >
        </li>
        <li>
          <a
            class="routerA"
            (click)="clickMenuItem($event)"
            [routerLink]="['/categorie', 'senior']"
            >Senior</a
          >
        </li>
      </ul>
    </li>
      <li
      (mouseenter)="displayMenu($event)"
      (mouseleave)="hideMenu($event)"
    >
      <a>Foire aux questions</a>
      <ul>
        <li>
          <a
            class="routerA"
            (click)="clickMenuItem($event)"
            [routerLink]="['/faq', 'clubs']"
            >Clubs</a
          >
        </li>
      </ul>
    </li>
      <li>
        <a	(click)="toggleMenuOpening()"
						[routerLink]="['/news', 'news-actu']">
					Actualités
				</a>
      </li>
      <li>
        <a (click)="toggleMenuOpening()" [routerLink]="['/calendar', 'global']"
          >Calendrier</a
        >
      </li>
      <li (mouseenter)="displayMenu($event)" (mouseleave)="hideMenu($event)">
        <a>Administratif</a>
        <ul>
          <li>
            <a
              class="routerA"
              (click)="clickMenuItem($event)"
              [routerLink]="['/paperwork', 'passeports']"
              >Passeports</a
            >
          </li>
          <li>
            <a
              class="routerA"
              (click)="clickMenuItem($event)"
              [routerLink]="['/paperwork', 'rank-exams']"
              >Passages de Grades</a
            >
          </li>
          <li>
            <a
              class="routerA"
              (click)="clickMenuItem($event)"
              [routerLink]="['/paperwork', 'ghn']"
              >Grades sur dossier</a
            >
          </li>
          <li>
            <a
              class="routerA"
              (click)="clickMenuItem($event)"
              [routerLink]="['/paperwork', 'teaching-authorization']"
              >Autorisation d'enseigner</a
            >
          </li>
          <li>
            <a
              class="routerA"
              (click)="clickMenuItem($event)"
              [routerLink]="['/paperwork', 'bf']"
              >Brevet fédéral</a
            >
          </li>
          <li>
            <a
              class="routerA"
              (click)="clickMenuItem($event)"
              [routerLink]="['/paperwork', 'rules']"
              >Documents réglementaires</a>
          </li>
          <li>
            <a
              class="routerA"
              (click)="clickMenuItem($event)"
              [routerLink]="['/paperwork', 'elections']"
              >Élections de Ligue</a>
          </li>
        </ul>
      </li>
      <li (mouseenter)="displayMenu($event)" (mouseleave)="hideMenu($event)">
        <a>Collège technique régional</a>
        <ul>
          <li>
            <a
              class="routerA"
              (click)="clickMenuItem($event)"
              [routerLink]="['/CTR', 'equipe-ctr']"
              >L'équipe</a
            >
          </li>
          <!--<li>
            <a
              class="routerA"
              (click)="clickMenuItem($event)"
              [routerLink]="['/CTR', 'presentation-ctr']"
              >Présentation</a
            >
          </li>
          <li>
            <a
              class="routerA"
              (click)="clickMenuItem($event)"
              [routerLink]="['/CTR', 'actu-ctr']"
              >Actualité</a
            >
					</li>-->
          <li>
            <a
              class="routerA"
              (click)="clickMenuItem($event)"
              [routerLink]="['/CTR', 'pdg']"
              >Passage de grades</a
            >
          </li>
          <!--<li>
            <a
              class="routerA"
              (click)="clickMenuItem($event)"
              [routerLink]="['/CTR', 'aikido-junior']"
              >Aikïdo Junior</a
            >
					</li>-->
        </ul>
      </li>
      <li (mouseenter)="displayMenu($event)" (mouseleave)="hideMenu($event)">
      	<a>Comité directeur</a>
        <ul>
          <li>
            <a
              class="routerA"
              (click)="clickMenuItem($event)"
              [routerLink]="['/comity-director', 'equipe-cd']"
              >Équipe</a
            >
          </li>
          <!--<li>
            <a
              class="routerA"
              (click)="clickMenuItem($event)"
              [routerLink]="['/comity-director', 'presentation-cd']"
              >Présentation</a
            >
          </li>
          <li>
            <a
              class="routerA"
              (click)="clickMenuItem($event)"
              [routerLink]="['/comity-director', 'actu-cd']"
              >Actualité</a
            >
					</li>-->
          <li>
            <a
              class="routerA"
              (click)="clickMenuItem($event)"
              [routerLink]="['/comity-director', 'assurance']"
              >Assurance</a
            >
          </li>
				</ul>
      </li>

      <span>|</span>
      <a target="_blank" href="https://www.facebook.com/"><i class="fa-brands fa-facebook"></i></a>
      <a target="_blank" href="https://www.instagram.com/"><i class="fa-brands fa-instagram"></i></a>
    </ul>
  </nav>
</div>
