import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';


import { FooterComponent } from './footer/footer.component';
import { MenuComponent } from './menu/menu.component';





@NgModule({
  declarations: [
    FooterComponent,
    MenuComponent
  ],
	imports: [
		CommonModule,
		RouterModule
	],
  	exports: [
  		MenuComponent,
  		FooterComponent
  	]
})
export class NavigationModule { }
