<footer>
	<p class="upper-footer">
	La Ligue Grand Est fédère les énergies de l’Aïkido à l’échelle de la région Grand Est. Elle peut
	s'appuyer sur les trois CID (Comité Interdépartementaux Alsace, Champagne, et Lorraine)
	et s’incrit dans la dynamique nationale de la FFAAA.
	</p>
	<div>
    <section>
        <p>
            Ligue Grand Est de la FFAAA<br>
						Maison régionale des sports<br>
						13, rue Jean Moulin - CS 70001<br>
						54&nbsp;510 Tomblaine
            <a class="button-outline" href="mailto:grandest@aikido.com.fr">grandest@aikido.com.fr</a>
        </p>
    </section>

    <section>
        <p>
            Délégué Protection des Données <br>
            Maxime Salaun <br>
            <a class="button-outline" [routerLink] ="['/generality', 'ppd']">Potilique des Protection des données</a>
        </p>
    </section>
	</div>
</footer>
