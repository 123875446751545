import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


const routes: Routes = [
	{
		path: 'generality',
		loadChildren: () => import('./generality/generality.module').then(m => m.GeneralityModule)
	},
	{
		path: 'clubs',
		loadChildren: () => import('./clubs/clubs.module').then(m => m.ClubsModule)
	},
	{
		path: 'faq',
		loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule)
	},
	{
		path: 'CTR',
		loadChildren: () => import('./CTR/ctr.module').then(m => m.CtrModule)
	},
	{
		path: 'comity-director',
		loadChildren: () => import('./comity-director/comity-director.module').then(m => m.ComityDirectorModule)
	},
	{
		path: 'categorie',
		loadChildren: () => import('./categorie/categorie.module').then(m => m.CategorieModule)
	},
	{
		path: 'calendar',
		loadChildren: () => import('./calendar/calendar.module').then(m => m.CalendarModule)
	},

	{
		path: 'operation',
		loadChildren: () => import('./operation/operation.module').then(m => m.OperationModule)
	},

	{
		path: 'paperwork',
		loadChildren: () => import ('./paperwork/paperwork.module').then(m => m.PaperworkModule)
	},
	{
		path: 'news',
		loadChildren: () => import ('./news/news-actu.module').then(m => m.NewsModule)
	},
	{
		path: '**',
		redirectTo: 'generality/welcome'
	}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
