import { Component, OnInit, Renderer2, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, AfterViewInit {
	@ViewChild('navBar') navBar!: ElementRef;

	menuBarOpened: boolean = false;

  constructor(
  	private renderer: Renderer2
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
  }

	displayMenu($event: any) {
		this.renderer.addClass($event.target.children[1], 'display-li');
	}

	hideMenu($event: any) {
		this.renderer.removeClass($event.target.children[1], 'display-li');
	}

	clickMenuItem($event: any) {
		this.renderer.removeClass($event.target.parentNode.parentNode, 'display-li');
		this.toggleMenuOpening();
	}

	
	toggleMenuOpening() {
		if (this.menuBarOpened) {
			this.renderer.removeClass(this.navBar.nativeElement, 'opened');
		} else {
			this.renderer.addClass(this.navBar.nativeElement, 'opened');
		}
		this.menuBarOpened = !this.menuBarOpened;
	}

	closeMenu() {
		this.renderer.removeClass(this.navBar.nativeElement, 'opened');
		this.menuBarOpened = false;
	}
}
