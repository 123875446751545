<app-menu></app-menu>
	
<div class="page-wrapper" style="margin: 0;">
	<div class="content-wrapper">
		<router-outlet></router-outlet>
	</div>
</div>

<div class="footer-wrapper">
		<app-footer></app-footer>
</div>
